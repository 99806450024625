// COLORS

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$neutral-placeholder: #C1CBD9;
$neutral-color-border: #E2E8F0;

// -- Optimal RH/Jazzy Purple
$jazzy-purple-100: #FFFAFC;
$jazzy-purple-200: #FFEEF5;
$jazzy-purple-300: #FFD6E3;
$jazzy-purple-400: #F8A5C1;
$jazzy-purple-500: #F67AA7;
$jazzy-purple:     #F43189;
$jazzy-purple-700: #DA2B7A;
$jazzy-purple-800: #BD256A;
$jazzy-purple-deep: #8D0039;

// -- Optimal RH/Love Purple
$love-purple-200: #F4F0FF;
$love-purple-300: #EAE1FF;
$love-purple-400: #DFD2FF;
$love-purple-500: #D4C4FF;
$love-purple:     #C9B6FF;
$love-purple-700: #9D8CD2;
$love-purple-800: #7464A7;
$love-purple-deep: #4C3F7E;
