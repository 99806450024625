// Mettre à jour ce lien via : https://fonts.google.com/

@import url('https://fonts.googleapis.com/css2?family=Inter&family=Urbanist:wght@600;700&display=swap');

$line-height-base:            2.7;

/* ======= fonts à déclarer
*/
$fonts: ();
$fonts: (
		inter:'Inter',
		urbanist:'Urbanist',
);

$font-family-base: map-get($fonts,inter);
$font-size-base : 1.6rem;

$font-sizes: ();
$font-sizes: (
		12: 1.2rem,
		14: 1.4rem,
		16: 1.6rem,
		18: 1.8rem,
		20: 2rem,
		24: 2.4rem,
		26: 2.6rem,
		28: 2.8rem,
		30: 3rem,
		32: 3.2rem,
		37: 3.7rem,
		40: 4rem,
		46: 4.6rem,
		50: 5rem,
		73: 7.3rem,
);

/* ======= fonts weight
*/

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;

/* ======= line height
*/

$line-height: ();
$line-height: (
		14: 1.4rem,
		16: 1.6rem,
		17: 1.7rem,
		18: 1.8rem,
		20: 2rem,
		22: 2.2rem,
		24: 2.4rem,
		27: 2.7rem,
		30: 3rem,
		32: 3.2rem,
		35: 3.5rem,
		36: 3.6rem,
		37: 3.7rem,
		40: 4rem,
		44: 4.4rem,
		46: 4.6rem,
		50: 5rem,
		52: 5.2rem,
		60: 6rem,
		73: 7.3rem,
		93: 9.3rem,
		100: 10rem,
		300: 30rem,
);

/* ======= letter spacing
*/

$letter-spacing: ();
$letter-spacing: (
	28: 2.8px,
);

/* ======= utilities
*/

$utilities: map-merge(
				$utilities,(
				"font-family":(
						rfs:true,
						property: font-family,
						responsive: true,
						class: font,
						values: $fonts
				),
				"font-size": (
						rfs: true,
						property: font-size,
						responsive: true,
						class: fs,
						values: $font-sizes
				),
				"font-weight": (
						property: font-weight,
						class: fw,
						responsive: true,
						values: (
								light: $font-weight-light,
								normal: $font-weight-normal,
								medium: $font-weight-medium,
								semi-bold: $font-weight-semibold,
								bold: $font-weight-bold
						)
				),
				"line-height": (
						property: line-height,
						class: lh,
						responsive: true,
						values: $line-height
				),
				"text-color": (
						property: color,
						class: text,
						responsive: true,
						values: $theme-colors
				),
				"letter-spacing": (
						property: letter-spacing,
						class: letter-spacing,
						responsive: true,
						values: $letter-spacing
				)
		)
);