
$enable-shadows : false;

/* ======= breakpoints
*/

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1400px,
		xxxl: 1900px
);

/* ======= shadow
*/

$box-shadow-sm: 0 .5rem 1rem rgba($black, .05);
$box-shadow: -2px 23px 36px 27px rgba($black, .05);
$box-shadow-lg: 0px 27.1989px 36.2653px rgba($black, .15);

/* ======= card
*/

$card-spacer-y: 20px;
$card-spacer-x: 30px;
$card-border-width: 0;
$card-box-shadow: 0 1rem 4rem 0 rgba($black,0.05);

/* ======= nav link
*/

$nav-link-padding-x: 2rem;
$nav-link-padding-y: 1.2rem;
$nav-pills-border-radius: 0;
$nav-link-font-size: map-get($font-sizes,18);
$nav-pills-link-active-color:       $primary;
$nav-pills-link-active-bg:          none;
$nav-link-color: $white;

/* ======= btn
*/

$btn-padding-y: map-get($spacers,16);
$btn-padding-x: map-get($spacers,40);
$btn-font-family: map-get($fonts,urbanist);
$btn-font-size: map-get($font-sizes,18);
$btn-font-weight: $font-weight-bold;
$btn-line-height: 2.7rem;
$btn-box-shadow: unset;
$btn-border-radius: map-get($border-widths,24);

/* ======= accordion
*/

$accordion-button-focus-box-shadow: unset;
$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");