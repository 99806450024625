
$enable-negative-margins : true;

/* ======= spacers
*/

$spacer: 1rem;
$spacers: (
		0: 0,
		1: 0.1rem,
		2: 0.2rem,
		3: 0.3rem,
		4: 0.4rem,
		5: 0.5rem,
		8: 0.8rem,
		10: 1rem,
		11: 1.1rem,
		12: 1.2rem,
		13: 1.3rem,
		14: 1.4rem,
		15: 1.5rem,
		16: 1.6rem,
		17: 1.7rem,
		18: 1.8rem,
		19: 1.9rem,
		20: 2.0rem,
		21: 2.1rem,
		22: 2.2rem,
		23: 2.3rem,
		24: 2.4rem,
		26: 2.6rem,
		27: 2.7rem,
		28: 2.8rem,
		30: 3rem,
		32: 3.2rem,
		33: 3.3rem,
		34: 3.4rem,
		35: 3.5rem,
		36: 3.6rem,
		37: 3.7rem,
		40: 4rem,
		42: 4.2rem,
		44: 4.4rem,
		46: 4.6rem,
		47: 4.7rem,
		48: 4.8rem,
		50: 5rem,
		55: 5.5rem,
		60: 6rem,
		61: 6.1rem,
		62: 6.2rem,
		64: 6.4rem,
		67: 6.7rem,
		70: 7rem,
		72: 7.2rem,
		76: 7.6rem,
		80: 8rem,
		85: 8.5rem,
		90: 9rem,
		100: 10rem,
		105: 10.5rem,
		110: 11rem,
		116: 11.6rem,
		120: 12rem,
		124: 12.4rem,
		126: 12.6rem,
		128: 12.8rem,
		140: 14rem,
		150: 15rem,
		160: 16rem,
		164: 16.4rem,
		180: 18rem,
		186: 18.6rem,
		200: 20rem,
		214: 21.4rem,
		220: 22rem,
		235: 23.5rem,
		247: 24.7rem,
		250: 25rem,
		254: 25.4rem,
		261: 26.1rem,
		278: 27.8rem,
		300: 30rem,
		360: 36rem,
		474: 47.4rem,
);

/* ======= gutters
*/

$grid-gutter-width: 2.4rem;

$gutters: ();
$gutters: (
		0: 0,
		1: 0.1rem,
		2: 0.2rem,
		3: 0.3rem,
		4: 0.4rem,
		5: 0.5rem,
		8: 0.8rem,
		12: 1.2rem,
		16: 1.6rem,
		20: 2rem,
		24: 2.4rem,
		26: 2.6rem,
		32: 3.2rem,
		48: 4.8rem,
		60: 6rem,
		75: 7.5rem,
		115: 11.5rem,
);
